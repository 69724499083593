import React from 'react';
import {useIntl} from 'react-intl';
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { GridRenderCellParams } from '@mui/x-data-grid-pro';

interface ViewAlarmButtonProps {
    params: GridRenderCellParams;
    dataset: string;
}

const ViewAlarmButton: React.FC<ViewAlarmButtonProps> = ({ params, dataset }) => {
    const intl = useIntl();

    const buttonLabel = intl.messages[`drilldown.${dataset}.button_label`]
        ? intl.formatMessage({ id: `drilldown.${dataset}.button_label` })
        : intl.formatMessage({ id: "drilldown.viewAlarms" });

    const url = prepareUrl(params, dataset);

    return (
        <Link to={url} style={{ textDecoration: "none" }}>
            <Button color="secondary" variant="outlined">
                {buttonLabel}
            </Button>
        </Link>
    );
}

function prepareUrl(params: GridRenderCellParams, dataset: string): string {
    
    // Depending on metric (=dataset), the range of alarms is encoded in different ways:
    //  1) in case of chattering/fleeting or most frequent alarms, 
    //      range `from`..`to` is encoded in the current cell's value (cell with the `View Alarms` button)
    //  2) in case of chattering/fleeting alarms for tag, range `from`..`to` is encoded in the `start` and `end` columns
    let [rangeStart, rangeEnd] = params.value?.toString().split("..") || [
        new Date().toISOString(),
        new Date().toISOString(),
    ];

    if (
      dataset === "chattering_alarms_for_tag" ||
      dataset === "fleeting_alarms_for_tag"
    ) {
      rangeStart = params.row.start.toISOString();
      rangeEnd = params.row.end.toISOString();
    }

    // If the range does not contain milliseconds, add them
    if (!rangeStart.includes("."))
        rangeStart = rangeStart.replace("Z", ".000Z");
    if (!rangeEnd.includes(".")) 
        rangeEnd = rangeEnd.replace("Z", ".999Z");

    // If end of the range is 0 milliseconds, change it to 999 - add the whole second.
    // That's because backend only uses second precision for calculation of all metrics, and truncates milliseconds. 
    // So in order to get correct list of alarms/events, we need to include the whole second, not just .000 ms
    if (rangeEnd.includes(".000"))
        rangeEnd = rangeEnd.replace(".000", ".999");

    const newRange = `${rangeStart}..${rangeEnd}`;
    const tagName = params?.row?.tagName;

    let url = '';

    if (dataset === "chattering_alarms" || dataset === "fleeting_alarms") {
        const newDataset = dataset === "chattering_alarms"
            ? "chattering_alarms_for_tag"
            : "fleeting_alarms_for_tag";
        
        url = `/dashboard/drill-down/${newDataset}/${newRange}`;
    } else {
        url = `/dashboard/alarm-list/${dataset}/${newRange}`;
    }
    if (tagName && tagName !== "") {
        url = url.concat(`/${tagName}`);
    }
    return url;
}

export default ViewAlarmButton;
