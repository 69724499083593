import React from "react";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { FormattedDate, FormattedMessage, FormattedTime, IntlShape } from "react-intl";

// Helpers
import { getExtendedStringFilterOptions } from "../helpers/GridFilterHelpers"
import DateTimeCell from "../pages/table/DateTimeCell";
import ViewAlarmButton from "../pages/table/ViewAlarmsButton";

interface ColumnOptions {
    size?: number;
    width?: number;
}

const getDateTimeRenderer = (timezone?: string, showMilliseconds?: boolean) => {
    return (params: GridRenderCellParams) => {
        return <DateTimeCell params={params} timezone={timezone ?? 'UTC'} showMilliseconds={showMilliseconds ?? false}></DateTimeCell>;
    };
}
  
const getAlarmRangeRenderer = (dataset: string) =>{
    return (params: GridRenderCellParams) => {
        return <ViewAlarmButton params={params} dataset={dataset} />
    };
};

const getDurationRenderer = (intl: IntlShape) => {
    /**
   * Formats durations of time.
   * @param value is the duration in seconds
   * @returns a typography component displaying the duration in a user-friendly way.
   */
  return (params: GridRenderCellParams) => {
    var seconds = params.value as number;
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor(seconds / 3600) % 24;
    const minutes = Math.floor(seconds / 60) % 60;
    seconds = seconds % 60;
    if (days > 0) {
      return (
        <Box sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          {hours > 0 ? (
            <FormattedMessage
              id="duration.daysAndHours"
              values={{ days: days, hours: hours }}
            />
          ) : (
            <FormattedMessage id="duration.days" values={{ days: days }} />
          )}
        </Box>
      );
    } else {
      return (
        <Box sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
          <FormattedMessage
            id="duration.hoursMinutesAndSeconds"
            values={{
              hours: hours,
              minutes: minutes.toString().padStart(2, "0"),
              seconds: seconds.toString().padStart(2, "0"),
            }}
          />
        </Box>
      );
    }
  };
  }

  
const getDateRenderer = (serverTimeZone?: string) => {
return (params: GridRenderCellParams) => {
  return (
    <Box sx={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      <FormattedDate
        value={params.value as Date}
        day={"2-digit"}
        month={"2-digit"}
        year={"numeric"}
        timeZone={serverTimeZone}
      />
    </Box>
  );
}
};

const getTimeRenderer = (serverTimeZone?: string) => {
return (params: GridRenderCellParams) => {
  return (
    <Box sx={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    }}>
      <FormattedTime
        value={params.value as Date}
        hour={"2-digit"}
        minute={"2-digit"}
        timeZone={serverTimeZone}
      />
    </Box>
  );
};
}

const UpdateColumnOptions = (column: GridColDef, options?: ColumnOptions ) => {
    if (options?.width)
        column.width = options?.width;
    else
        column.flex = options?.size || 1;
};

export function FieldFactory( type: string, dataset: string, intl: IntlShape, serverTimeZone?: string, options?: ColumnOptions ) : GridColDef {
    switch (type) {
        case "tagName":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.pointName" }),
                field: "tagName",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size || 2,
              }
        case "alarmText":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.alarmText" }),
                field: "alarmText",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size || 2,
            }
        case "areas":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.alarmAreas" }),
                field: "areas",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size || 1,
              }
        case "extText1":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.extText1" }),
                field: "extText1",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size || 1,
              }
       case "extText2":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.extText2" }),
                field: "extText2",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size || 1,
              }
        case "count":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.count" }),
                field: "count",
                type: "number",
                flex: options?.size || 1,
              }
        case "alarmCount":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.alarmCount" }),
                field: "count",
                type: "number",
                flex: options?.size || 1,
              }
        case "totalTransitions":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.totalTransitions" }),
                field: "count",
                type: "number",
                flex: options?.size ||1,
              } 
        case "range":
            const column: GridColDef = {
                headerName: " ",
                field: "range",
                renderCell: getAlarmRangeRenderer(dataset),
                filterable: false,
                sortable: false,
                hideable: false,
              }
            UpdateColumnOptions(column, options);              

            return column;
        case "start":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.start" }),
                field: "start",
                type: "dateTime",
                renderCell: getDateTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
              }
        case "end":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.end" }),
                field: "end",
                type: "dateTime",
                renderCell: getDateTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
              }
        case "duration":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.duration" }),
                field: "duration",
                type: "number",
                renderCell: getDurationRenderer(intl),
                flex: options?.size ||1,
              }
        case "peakRate":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.peakRate" }),
                field: "peakRate",
                type: "number",
                flex: options?.size ||1,
              }
        case "earliestOccasion":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.earliestOccasion" }),
                field: "start",
                type: "dateTime",
                renderCell: getDateTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
              }
        case "latestOccasion":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.latestOccasion" }),
                field: "end",
                type: "dateTime",
                renderCell: getDateTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
              }
        case "totalDuration":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.totalDuration" }),
                field: "duration",
                type: "number",
                renderCell: getDurationRenderer(intl),
                flex: options?.size ||1,
              }
        case "closeType":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.closeType" }),
                field: "closeType",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size ||1,
              }
        case "transitionsCount":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.transitionsCount" }),
                field: "count",
                type: "number",
                flex: options?.size ||1,
              }
        case "from":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.date" }),
                field: "from",
                type: "date",
                renderCell: getDateRenderer(serverTimeZone),
                flex: options?.size ||2,
              }
        case "valueText":
            return {
                headerName: intl.formatMessage({id: "dashboard.headers.value"}),
                field: "valueText",
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
                flex: options?.size ||2,
              }
        case "value":
            return {
                headerName: intl.messages["drilldown." + dataset + ".unit"] ? intl.formatMessage({id: "drilldown." + dataset + ".unit"}) : intl.formatMessage({id: "drilldown.viewAlarms"}),
                field: "value",
                type: "number",
                flex: options?.size ||2,
              }
        case "date":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.date" }),
                field: "date",
                type: "date",
                renderCell: getDateRenderer(serverTimeZone),
                flex: options?.size ||2,
              }
        case "timeFrom":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.timeFrom" }),
                field: "from",
                type: "date",
                renderCell: getTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
                filterable: false,
                sortable: false,
              }
        case "timeTo":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.timeTo" }),
                field: "to",
                type: "date",
                renderCell: getTimeRenderer(serverTimeZone),
                flex: options?.size ||1,
                filterable: false,
                sortable: false,
              }
        case "timeLast":
        return {
                headerName: intl.formatMessage({ id: "dashboard.headers.dateTime" }),
                field: "timeLast",
                flex: options?.size ||2,
                renderCell: getDateTimeRenderer(serverTimeZone, true), // show with milliseconds
                type: "dateTime",
              }
        case "source":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.source" }),
                field: "source",
                flex: options?.size ||1,
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
            }
        case "logicalNodeName":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.logicalNodeName" }),
                field: "logicalNodeName",
                flex: options?.size ||1,
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
            }
        case "operator":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.operator" }),
                field: "operator",
                flex: options?.size ||1,
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
            }
        case "priority":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.priority" }),
                field: "priority",
                flex: options?.size ||1,
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
            }
        case "state":
            return {
                headerName: intl.formatMessage({ id: "dashboard.headers.state" }),
                field: "state",
                flex: options?.size ||1,
                type: "string",
                filterOperators: getExtendedStringFilterOptions(intl),
            }
                                              
    }

    //Should not come here, only if a field type does not exist
    return {
        headerName: type + " field undefined",
        field: type,
        type: "string",
        flex: options?.size ||1,
    };
}