import React, {useState, useEffect} from "react";
import { useIntl } from 'react-intl';
import Box from "@mui/material/Box";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

interface DateTimeCellProps {
    params: GridRenderCellParams;
    timezone: string;
    showMilliseconds: boolean;
}

const DateTimeCell: React.FC<DateTimeCellProps> = ({ params, timezone, showMilliseconds }) => {
    const intl = useIntl();
    const [value, setValue] = useState<string>('');

    useEffect(() => {
        if (params.field === 'end' && params.value === 'no_end' || params.value === '') {
            setValue(intl.formatMessage({ id: 'dashboard.stillActive' }));
            return;
        }

        const date = new Date(params.value as string);
        const dateFormatted = intl.formatDate(date, { timeZone: timezone });
        const timeFormatted = intl.formatTime(date, {
            timeZone: timezone,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        });
        if (showMilliseconds) {
            const millis = date.getMilliseconds().toString().padStart(3, '0');
            setValue(`${dateFormatted} ${timeFormatted}.${millis}`);
        } else {
            setValue(`${dateFormatted} ${timeFormatted}`);
        }
    }, [params, timezone, showMilliseconds, intl]);

    return (
        <Box sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>
            {value}
        </Box>
    );
}

export default DateTimeCell;